import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';

import * as styles from './Content.module.css';

const Content = ({
	children,
	spacing = 'md',
	uniformHeadings,
	constrict,
	justify,
	as: Component = 'div',
	className = '',
	...rest
}: {
	children: React.ReactNode;
	spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
	uniformHeadings?: boolean;
	constrict?: boolean;
	justify?: 'left' | 'center' | 'right';
	as?: React.ElementType;
	className?: string;
}): JSX.Element => {
	return (
		<Component
			className={cx(styles.content, styles[`${spacing}Spacing`], {
				[styles.constrict]: constrict,
				[styles.uniformHeadings]: uniformHeadings,
				[styles[`${justify}Justify`]]: justify,
				[className]: !!className,
			})}
			{...rest}>
			{children}
		</Component>
	);
};

export const MotionContent = ({
	children,
	spacing = 'md',
	constrict,
	as: Component = 'div',
	...rest
}: {
	children: React.ReactNode;
	spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
	constrict?: boolean;
	as?: React.ElementType;
}): JSX.Element => {
	const AnimatedComponent = motion(
		forwardRef((props, ref) => <Component {...props} ref={ref}></Component>)
	);

	return (
		<AnimatedComponent
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.6 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1, translateY: 0 },
				hidden: { opacity: 0, translateY: '1rem' },
			}}
			className={cx(styles.content, styles[`${spacing}Spacing`], {
				[styles.constrict]: constrict,
			})}
			{...rest}>
			{children}
		</AnimatedComponent>
	);
};

export default Content;
