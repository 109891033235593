import cx from 'classnames';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import * as styles from './Form.module.css';

import FieldGroup from '@components/Form/FieldGroup';
import Input from '@components/Form/Input';
import Label from '@components/Form/Label';
import TextArea from '@components/Form/TextArea';
import Content from '@components/Layout/Content/Content';
import Button from '@components/Molecule/Button/Button';
import Arrow from '@components/SVG/Arrow';
import Copy from '@components/Typography/Copy';

const ContactForm = ({ className = '' }: { className?: string }) => {
	const [formSent, setFormSent] = useState(false);
	const [formData, setFormData] = useState({});

	const encode = (data: any) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					'=' +
					encodeURIComponent(data[key])
			)
			.join('&');
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const form = e.target;

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': form.getAttribute('name'),
				...formData,
			}),
		})
			.then(() => {
				setFormSent(true);
				setFormData({});
			})
			.catch((error) => alert(error));
	};

	const handleChange = (e) => {
		let value;

		switch (e.target.type) {
			case 'checkbox':
				value = e.target.checked ? e.target.checked : false;
				break;

			default:
				value = e.target.value;
				break;
		}

		setFormData({
			...formData,
			[e.target.name]: value,
		});
	};

	return formSent ? (
		<Content>
			<Copy>
				Your message has been sent, and we'll be in contact soon.
			</Copy>

			<Button onClick={() => setFormSent(false)}>
				Send another <Arrow />
			</Button>
		</Content>
	) : (
		<motion.div
			initial="hidden"
			whileInView="visible"
			viewport={{ once: true, amount: 0.1 }}
			transition={{ duration: 0.35 }}
			variants={{
				visible: { opacity: 1 },
				hidden: { opacity: 0 },
			}}>
			<form
				name="contact"
				method="POST"
				data-netlify="true"
				netlify-honeypot="bot-field"
				autoComplete="off"
				id="contact"
				onSubmit={(e) => handleSubmit(e)}
				className={cx(styles.form, {
					[className]: !!className,
				})}>
				<div className={styles.layout}>
					<label hidden>
						Don't fill this out if you're human:{' '}
						<input name="bot-field" />
					</label>

					<div className={styles.lineItem}>
						<FieldGroup>
							<Label name="name" idFor="name">
								Your name
							</Label>
							<Input
								name="name"
								id="name"
								required
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>

						<FieldGroup>
							<Label name="tel" idFor="tel">
								Telephone
							</Label>
							<Input
								name="tel"
								id="tel"
								type="tel"
								// pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
								required
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>
					</div>

					<div className={styles.lineItem}>
						<FieldGroup>
							<Label name="email" idFor="email">
								Your email
							</Label>
							<Input
								name="email"
								id="email"
								type="email"
								required
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>

						<FieldGroup>
							<Label name="state-county" idFor="state-county">
								State/County
							</Label>
							<Input
								name="state-county"
								id="state-county"
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>
					</div>

					<div className={styles.lineItem}>
						<FieldGroup>
							<Label name="company" idFor="company">
								Company
							</Label>
							<Input
								name="company"
								id="company"
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>

						<FieldGroup>
							<Label name="country" idFor="country">
								Country
							</Label>
							<Input
								name="country"
								id="country"
								required
								onChange={(e) => handleChange(e)}
							/>
						</FieldGroup>
					</div>

					<FieldGroup>
						<Label name="message" idFor="message">
							Nature of enquiry
						</Label>
						<TextArea
							name="message"
							id="message"
							required
							onChange={(e) => handleChange(e)}
						/>
					</FieldGroup>

					<FieldGroup direction="row" gap="lg">
						<Input
							name="consent"
							id="consent"
							type="checkbox"
							onChange={(e) => handleChange(e)}
						/>

						<Label
							name="consent"
							idFor="consent"
							fontWeight="normal">
							Please tick here to be added to our mailing list and
							be kept up-to-date on all the latest news and
							product information. This form collects your first
							and last name, email address and phone number so
							that our team can communicate with you and provide
							assistance. Please check our Privacy Policy to see
							how we protect and manage your submitted data. By
							completing and submitting this form you give consent
							to having UHV Design collect your details via this
							form.
						</Label>
					</FieldGroup>
				</div>

				<Button type="submit" align="center">
					Send enquiry
					<Arrow />
				</Button>
			</form>
		</motion.div>
	);
};

export default ContactForm;
