import { Status, Wrapper } from '@googlemaps/react-wrapper';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import * as styles from './Map.module.css';

import Spinner from '@components/Molecule/Spinner/Spinner';

const render = (status) => {
	if (status === Status.LOADING)
		return (
			<div className={styles.background}>
				<Spinner />
			</div>
		);
	if (status === Status.FAILURE) return null;
	return null;
};

const Map = ({
	as: Component = 'div',
	className = '',
}: {
	as?: React.ElementType;
	className?: string;
}) => {

	const ref = useRef(null);
	const [map, setMap] = useState();

	useEffect(() => {
		if (ref.current && !map) {
			setMap(new window.google.maps.Map(ref.current, {}));
		}
	}, [ref, map]);

	// Add marker
	useEffect(() => {
		if (map) {
			map.setOptions({
				center: { lat: 50.893898154579006, lng: 0.150376627270929 },
				zoom: 17,
			});
		}
		new window.google.maps.Marker({
			position: { lat: 50.893898154579006, lng: 0.150376627270929 },
			map,
		});
	}, [map]);

	return (
		<div
			ref={ref}
			className={cx(styles.map, {
				[className]: !!className,
			})}></div>
	);
};

const InteractiveMap = () => {
	return (
		<Wrapper apiKey={process.env.GATSBY_GOOGLE_API_KEY} render={render}>
			<Map />
		</Wrapper>
	);
};

export default InteractiveMap;
