import cx from 'classnames';
import React from 'react';

import * as styles from './Form.module.css';

import Tick from '@components/SVG/Tick';

interface inputProps extends React.HTMLAttributes<HTMLInputElement> {
	name: string;
	type?: 'text' | 'number' | 'email' | 'tel' | 'checkbox';
	id: string;
	pattern?: string;
	value?: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
}

const Input = ({
	name,
	type = 'text',
	id,
	pattern,
	value,
	placeholder,
	required,
	disabled,
	...rest
}: inputProps): JSX.Element => {
	return (
		<div
			className={cx(styles.bounds, {
				[styles.checkbox]: type === 'checkbox',
			})}
		>
			<input
				type={type}
				name={name}
				id={id}
				pattern={pattern}
				placeholder={placeholder}
				value={value}
				required={required}
				disabled={disabled}
				className={styles.input}
				{...rest}
			/>

			{type === 'checkbox' && <Tick className={styles.tick} />}
		</div>
	);
};

export default Input;
