import React from 'react';

import * as styles from './Spinner.module.css';

const Spinner = () => {
	return (
		<div className={styles.spinner}>
			<div className={styles.line}></div>
			<div className={styles.line}></div>
			<div className={styles.line}></div>
			<div className={styles.line}></div>
		</div>
	);
};

export default Spinner;
