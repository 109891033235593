// extracted by mini-css-extract-plugin
export var boldFontWeight = "Form-module--bold--font-weight--m3VnU";
export var bounds = "Form-module--bounds--M3n51";
export var checkbox = "Form-module--checkbox--uE-tG";
export var fieldGroup = "Form-module--field-group--R4g-0";
export var form = "Form-module--form--MWs3u";
export var input = "Form-module--input--38RT5";
export var label = "Form-module--label--Gi1dd";
export var layout = "Form-module--layout--jJzi1";
export var lgGap = "Form-module--lg--gap--ur99u";
export var lineItem = "Form-module--line-item--O8f4D";
export var mdGap = "Form-module--md--gap--sxVn+";
export var normalFontWeight = "Form-module--normal--font-weight--dXAbs";
export var row = "Form-module--row--1j0Ve";
export var smGap = "Form-module--sm--gap--0IYsm";
export var textArea = "Form-module--text-area--STte1";
export var tick = "Form-module--tick--QMKio";