// extracted by mini-css-extract-plugin
export var centerJustify = "Content-module--center--justify--lSVx+";
export var constrict = "Content-module--constrict--9UNg3";
export var content = "Content-module--content--T4Aj5";
export var leftJustify = "Content-module--left--justify--c0JK7";
export var lgSpacing = "Content-module--lg--spacing--ICYFB";
export var mdSpacing = "Content-module--md--spacing--ngQ3z";
export var noneSpacing = "Content-module--none--spacing--7SIRh";
export var rightJustify = "Content-module--right--justify--xz5nl";
export var smSpacing = "Content-module--sm--spacing--P2B6F";
export var uniformHeadings = "Content-module--uniformHeadings--PaDs2";
export var xsSpacing = "Content-module--xs--spacing--sOd6S";