import { graphql } from 'gatsby';
import React from 'react';

import ContactForm from '@components/Form/ContactForm';
import Columns from '@components/Layout/Columns/Columns';
import Content, { MotionContent } from '@components/Layout/Content/Content';
import Grid from '@components/Layout/Grid/Grid';
import Lead from '@components/Layout/Lead/Lead';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Accordion from '@components/Molecule/Accordion/Accordion';
import InteractiveMap from '@components/Molecule/Map/Map';
import Copy from '@components/Typography/Copy';
import { MotionCopy } from '@components/Typography/Copy';
import H from '@components/Typography/H';
import { MotionH } from '@components/Typography/H';

const ContactPage = ({ data }: { data: any }) => {
	const { contactDetails } = data;

	return (
		<Page>
			<SEO
				title="Contact | UHV Design"
				description="Got a project you'd like to discuss? Contact us at sales@uhvdesign.com or use our contact page to find your nearest UHV Design representative. Contact us."
			/>

			<PageSection>
				<div>
					<MotionH level={2} as="h1">
						Contact
					</MotionH>
				</div>

				<Columns gap="md">
					<div className="column--space">
						<div>
							<Lead>
								<MotionH level={4} as="h2">
									Head office
								</MotionH>
							</Lead>

							<Grid spacing type="fixed">
								<MotionCopy as="address">
									UHV DESIGN LTD
									<br />
									Judges House
									<br />
									Lewes Road
									<br />
									Laughton
									<br />
									East Sussex
									<br />
									England, BN8 6BN
								</MotionCopy>

								<MotionContent>
									<a href="tel:+44 (0)1323 811188">
										T: +44 (0)1323 811188
									</a>

									<a href="fax:+44 (0)1323 811999">
										F: +44 (0)1323 811999
									</a>

									<a href="mailto:sales@uhvdesign.com">
										E: sales@uhvdesign.com
									</a>
								</MotionContent>
							</Grid>
						</div>

						<InteractiveMap />
					</div>

					<div>
						<ContactForm />
					</div>
				</Columns>
			</PageSection>

			<PageSection background="grey" padding="sm">
				<Columns stretch="latter">
					<Lead>
						<H level={3} as="h2">
							Our other locations
						</H>
					</Lead>

					{contactDetails.edges.length > 0 && (
						<Grid>
							{contactDetails.edges.map(
								({
									node,
								}: {
									node: {
										continent: string;
										regions: [];
									};
								}) => {
									const { continent, regions } = node;

									return (
										<Accordion label={continent}>
											<Grid type="twoColumn">
												{regions.map(
													(
														{
															region,
															company,
															location,
															telephone,
															mobile,
															email,
															website,
														},
														key: number
													) => (
														<div
															className="column--space"
															key={key}>
															<Content spacing="none">
																<Copy weight="bold">
																	{region}
																</Copy>
																<Copy>
																	{company}
																</Copy>
																{location && (
																	<Copy>
																		{
																			location
																		}
																	</Copy>
																)}
															</Content>

															<Content spacing="none">
																<a
																	href={`tel:${telephone}`}>
																	T:{' '}
																	{telephone}
																</a>

																{mobile &&
																	<a
																		href={`tel:${mobile}`}>
																		M:{' '}
																		{mobile}
																	</a>
																}

																<a
																	href={`mailto:${email}`}>
																	E: {email}
																</a>

																{website &&
																	<a
																		href={
																			website
																		}
																		target="_blank"
																		rel="">
																		W: {website}
																	</a>
																}
															</Content>
														</div>
													)
												)}
											</Grid>
										</Accordion>
									);
								}
							)}
						</Grid>
					)}
				</Columns>
			</PageSection>
		</Page>
	);
};

export const query = graphql`
	query {
		contactDetails: allContactJson {
			edges {
				node {
					continent
					regions {
						company
						location
						email
						region
						telephone
						mobile
						website
					}
				}
			}
		}
	}
`;

export default ContactPage;
