import React from 'react';

import * as styles from './Form.module.css';

interface textAreaProps extends React.HTMLAttributes<HTMLTextAreaElement> {
	name: string;
	id: string;
	placeholder?: string;
	required?: boolean;
}

const TextArea = ({
	name,
	id,
	placeholder,
	required,
	...rest
}: textAreaProps) => {
	return (
		<textarea
			name={name}
			id={id}
			cols={30}
			rows={2}
			placeholder={placeholder}
			required={required}
			className={styles.textArea}
			{...rest}
		/>
	);
};

export default TextArea;
