import React from 'react';

const Tick = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="16.121"
			height="12.518">
			<path
				d="m1.061 5.734 4.664 4.663 9.336-9.336"
				fill="none"
				stroke="#2b2d42"
				strokeWidth="3"
			/>
		</svg>
	);
};

export default Tick;
