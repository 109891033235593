import cx from 'classnames';
import React from 'react';

import * as styles from './Form.module.css';

const Label = ({
	children,
	name,
	idFor,
	disabled,
	fontWeight = 'bold',
}: {
	children: React.ReactNode;
	name: string;
	idFor: string;
	disabled?: boolean;
	fontWeight?: 'normal' | 'bold';
}): JSX.Element => {
	return (
		<label
			name={name}
			htmlFor={idFor}
			disabled={disabled}
			className={cx(styles.label, {
				[styles[`${fontWeight}FontWeight`]]: fontWeight,
			})}
		>
			{children}
		</label>
	);
};

export default Label;
