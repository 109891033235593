import cx from 'classnames';
import React from 'react';

import * as styles from './Form.module.css';

const FieldGroup = ({
	children,
	direction = 'column',
	gap = 'sm',
}: {
	children: React.ReactNode;
	direction?: 'row' | 'column';
	gap?: 'sm' | 'md' | 'lg';
}): JSX.Element => {
	return (
		<div
			className={cx(styles.fieldGroup, {
				[styles.row]: direction === 'row',
				[styles[`${gap}Gap`]]: gap,
			})}
		>
			{children}
		</div>
	);
};

export default FieldGroup;
